import PopNotification from "components/shared/PopNotification";
import printJS from "print-js";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();

  const timeString = `${year}${month}${day}${hour}${minute}${second}`;
  return timeString;
};

export const downloadDoc = async (response, type, fileName = "data") => {
  try {
    const blobType = {
      pdf: "application/pdf",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      txt: "text/plain",
      zip: "application/zip",
      xls: "application/vnd.ms-excel", // Untuk file Excel 97-2003
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      doc: "application/msword", // Untuk file Word 97-2003
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ppt: "application/vnd.ms-powerpoint", // Untuk file PowerPoint 97-2003
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      mp4: "video/mp4",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      other: "application/octet-stream", // Default untuk tipe file lain yang tidak dikenali
    };

    const blob = new Blob([response.data], {
      type: blobType[type],
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}_${generateTimeString()}.${type}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    // console.log(e);
    PopNotification("danger", "error", "Download File gagal.");
  }
};

export const downloadDocFromUrl = async (response, type, fileName = "data") => {
  try {
    // Define the MIME types for the file types
    const blobType = {
      pdf: "application/pdf",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      txt: "text/plain",
      zip: "application/zip",
      xls: "application/vnd.ms-excel",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      mp4: "video/mp4",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      other: "application/octet-stream",
    };

    // Fetch the file data from the URL
    const responseData = await fetch(response.data);

    if (!responseData.ok) {
      throw new Error("Network response was not ok.");
    }

    // Convert response to Blob
    const blob = await responseData.blob();

    // Use Blob type if specified
    const mimeType = blobType[type] || blobType["other"];

    // Create a new Blob with the correct type
    const blobWithType = new Blob([blob], { type: mimeType });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blobWithType);

    // Create a download link
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}_${generateTimeString()}.${type}`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (e) {
    console.error(e);
    PopNotification("danger", "error", "Download File gagal.");
  }
};

export const printDoc = async (response, setIsPrinting) => {
  try {
    const blob = new Blob([response.data], {
      type: "application/pdf",
      endings: "native",
    });
    blob.filename = "nama_file.pdf";

    const uri = URL.createObjectURL(blob);
    printJS({
      printable: uri,
      type: "pdf",
      onPrintDialogClose: function () {
        setIsPrinting(false);
      },
      onError: (e) => {
        // console.log(e);
      },
    });
    URL.revokeObjectURL(uri);
  } catch (e) {
    PopNotification("danger", "error", e);
  }
};

export const printReceipt = () => {
  const iframe = document.createElement("iframe");
  iframe.src = `/report/receipt.html`;
  iframe.style.width = "0";
  iframe.style.height = "0";

  document.body.appendChild(iframe);

  return () => {
    if (iframe.parentNode) {
      iframe.parentNode.removeChild(iframe);
    }
  };
};

export const convertToArabicNumbers = (num) => {
  const arabicNumbers =
    "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
  return new String(num).replace(/[0123456789]/g, (d) => {
    return arabicNumbers[d];
  });
};

export const isSequenceBroken = (data) => {
  const verseIds = data.map((item) => item.verse_id).sort((a, b) => a - b);

  for (let i = 1; i < verseIds.length; i++) {
    if (verseIds[i] - verseIds[i - 1] > 1) {
      return true;
    }
  }

  return false;
};

// export const caesarCipherDecrypt = (text, shift) => {
//   let decryptedText = "";
//   for (let i = 0; i < text.length; i++) {
//     let char = text[i];
//     if (char.match(/[a-z]/i)) {
//       let code = text.charCodeAt(i);
//       if (char === char.toUpperCase()) {
//         decryptedText += String.fromCharCode(
//           ((code - 65 - shift + 26) % 26) + 65
//         );
//       } else {
//         decryptedText += String.fromCharCode(
//           ((code - 97 - shift + 26) % 26) + 97
//         );
//       }
//     } else if (char.match(/[0-9]/)) {
//       decryptedText += String.fromCharCode(
//         ((char.charCodeAt(0) - 48 - shift + 10) % 10) + 48
//       );
//     } else {
//       decryptedText += char;
//     }
//   }
//   return decryptedText;
// };
export const caesarCipherDecrypt = (text, shift) => {
  let decryptedText = "";
  for (let i = 0; i < text.length; i++) {
    let char = text[i];
    // Hanya melakukan dekripsi untuk huruf a-z, A-Z, dan angka 0-9
    if (char.match(/[a-z]/i)) {
      let code = text.charCodeAt(i);
      if (char === char.toUpperCase()) {
        decryptedText += String.fromCharCode(
          ((code - 65 - shift + 26) % 26) + 65
        );
      } else {
        decryptedText += String.fromCharCode(
          ((code - 97 - shift + 26) % 26) + 97
        );
      }
    } else if (char.match(/[0-9]/)) {
      decryptedText += String.fromCharCode(
        ((char.charCodeAt(0) - 48 - shift + 10) % 10) + 48
      );
    } else {
      // Karakter selain a-z, A-Z, dan 0-9 tidak didekripsi
      decryptedText += char;
    }
  }
  return decryptedText;
};

export const secToReadString = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let timeStr = "";

  if (hours > 0) {
    timeStr += `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      timeStr += " "; // add space between hours and minutes
    }
    timeStr += `${minutes} min`;
  }

  return timeStr || "0 min";
};

export const getDateRangeArr = (date_from, date_to) => {
  const startDate = new Date(date_from);
  const endDate = new Date(date_to);
  const dateList = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 6 = Saturday
    if (dayOfWeek > 0 && dayOfWeek < 6) {
      // Only Monday to Friday
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      const _id = `${year}_${month}_${day}`; // Format _id as YYYY_MM_DD
      const formattedDate = `${day}/${month}/${String(year).slice(-2)}`; // Format date as DD/MM/YY

      dateList.push({ _id, date: formattedDate });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateList;
};

export const handleDownload = (url) => {
  try {
    const fileName = url.split("/").pop();
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (e) {
    console.error(e);
    PopNotification("danger", "error", "Download File gagal.");
  }
};
